// 
// user chat.scss
//

:root{
    --chat-contact-bg: #FFFFFF;
    --chat-group-bg: #D9FDD3;
    --chat-group-head: 8px 8px 0px 8px;
}

// user chat
::-webkit-scrollbar {
  width: .5rem;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: .25rem;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #a2adb7; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background:#a2adb7; 
}
.user-chat {
    background-color: $card-bg;
    box-shadow: 0 2px 4px rgba(15,34,58,.12);
    transition: all 0.4s;
    position: relative;
    height: 100vh;
    flex: 1;
    @media (max-width: 991.98px) {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        visibility: hidden;
        transform: translateX(100%);
        z-index: 99;

        &.user-chat-show{
            visibility: visible;
            transform: translateX(0);
        }
    }
    &-loading {
        background: #fff;
        font-size: 1.5rem;
    }
}

.user-chat-nav {
    .nav-btn {
        height: 40px;
        width: 40px;
        line-height: 40px;
        box-shadow: none;
        padding: 0;
        font-size: 20px;
        color: $gray-600;
    }
}


.chat-conversation {
    background-color: #efeae2;

    li {
        clear: both;
    }

    .chat-avatar {
        margin-right: 16px;

        img {
            width: 36px;
            height: 36px;
            border-radius: 50%;
        }
        @media (max-width: 576px ) {
            display: none;
        }
    }

    .chat-day-title {
        position: relative;
        text-align: center;
        margin-bottom: 24px;
        margin-top: 12px;

        .title {
            background-color: $border-color;
            position: relative;
            font-size: 13px;
            z-index: 1;
            padding: 6px 12px;
            border-radius: 5px;
        }

        &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            left: 0;
            right: 0;
            background-color: $border-color;
            top: 10px;
        }

        .badge {
            font-size: 12px;
        }
    }

    .conversation-list {
        margin-bottom: 24px;
        display: inline-flex;
        position: relative;
        align-items: flex-end;

        .ctext-wrap {
            display: flex;
        }

        .ctext-wrap-content {
            // background-color: $chat-primary-bg;
            background-color: var(--chat-contact-bg);
            border-radius: 8px 8px 8px 0;
            color: $white;
            position: relative;
            box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075);
            word-wrap: break-word;
            &:before {
                content: "";
                position: absolute;
                border: transparent;
                border-left-color: var(--chat-contact-bg);
                border-top-color: var(--chat-contact-bg);
                left: 0;
                bottom: -10px;
                filter: drop-shadow(0 .125rem .25rem rgba(0, 0, 0, .075));
            }
            >div
                >p {
                    color: black;
                }
            >div {
                >div
                    >p {
                        color: #7a7f9a;
                    }
            }
            .message-video{
                display: flex;
                background-color: #000;
                border: 0px solid transparent !important;
            }
            video{
                width: 100%;
                max-height: 312px;
            }
            .filename{
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                overflow: hidden;
                max-height: 50.4px;
                max-width: 196px;
            }
        }

        .conversation-name {
            font-weight: $font-weight-medium;
            font-size: 14px;
        }

        .dropdown {
            .dropdown-toggle {
                font-size: 18px;
                padding: 4px;
                color: $gray-600;

                @media (max-width: 575.98px) {
                    display: none;
                }
            }

        }

        .chat-time {
            font-size: 12px;
            margin-top: 4px;
            text-align: right;
            color: rgba($white, 0.5);
        }

        .message-img {
            border-radius: .2rem;
            position: relative;

            .message-img-list {
                position: relative;
            }

            img {
                width: 312px;
                max-height: 312px;
                object-fit: cover;
                border: 0 solid transparent !important;
            }

            .message-img-link {
                position: absolute;
                right: 10px;
                bottom: 10px;

                li {
                    >a {
                        font-size: 18px;
                        color: $white;
                        display: inline-block;
                        line-height: 30px;
                        width: 30px;
                        height: 30px;
                        text-align: center;
                    }
                }
            }
        }
    }

    .right {

        .chat-avatar {
            order: 3;
            margin-right: 0px;
            margin-left: 16px;
        }

        .chat-time {
            text-align: left;
            color: $gray-600;
        }

        .conversation-list {
            float: right;
            text-align: right;

            .ctext-wrap {

                .ctext-wrap-content {
                    order: 2;
                    // background-color: $chat-secondary-bg;
                    background-color: var(--chat-group-bg);
                    color: $dark;
                    text-align: left;
                    border-radius: var(--chat-group-head);

                    &:before {
                        border: transparent;
                        border-top-color: var(--chat-group-bg);
                        border-right-color: var(--chat-group-bg);
                        left: auto;
                        right: 0px;
                    }
                }
            }

            .dropdown {
                order: 1;
            }
        }

        .dot {
            background-color: $dark;
        }
    }
}

.chat-conversation__error-button{
    background: none;
    border: 0;
}

.chat-conversation__error-text{
    font-size: 12px;
    color: hsl(1, 98%, 65%);
}

.chat-conversation {
    height: calc(100vh - 116px - 90px);
    @media (max-width: 991.98px) {
        height: calc(100vh - 230px);
        margin-top: 91px;
        margin-bottom: 139px;
    }
}

.chat-input-links{
    .list-inline-item:not(:last-child){
        margin: 0;
    }
}

.animate-typing {
	.dot {
		display: inline-block;
		width: 4px;
		height: 4px;
		border-radius: 50%;
		margin-right: -1px;
		background: $white;
        animation: wave 1.3s linear infinite;
        opacity: 0.6;

		&:nth-child(2) {
			animation-delay: -1.1s;
		}

		&:nth-child(3) {
			animation-delay: -0.9s;
		}
	}
}

@keyframes wave {
	0%, 60%, 100% {
		transform: initial;
	}

	30% {
		transform: translateY(-5px);
	}
}

@media (min-width: 992px){
    .emoji-dropdown{
        .dropdown-menu-lg-right {
            right: 0!important;
            left: auto!important;
            width: 360px!important;
        }
    }
}

.img-status-message {
    position: absolute;
    top: 100px;
    border-radius: 5px;
    color: white;
    text-align: center;
    font-size: 12px;
    padding: 3px;
}

.dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    font-size: 0.9375rem;
    color: #495057;
    outline: none;
    border: 2px dashed rgba(114, 105, 239, 0.5);
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
}

@keyframes fade {
    0% { opacity: 0}
    100% { opacity: 1 }
}

.dropzone-img {
  width: 100%;
  height: 9rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-color: #F1F1F1;
  border-radius: 0.4rem;
  border: 2px solid rgba(114, 105, 239, 0.5);
}

.dropzone-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 1rem,
}

.dropzone-modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 0.75rem 1rem;
  width: 100%;
  border-bottom: 1px solid #f0eff5;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.with-files {
  border: none;
  margin: 0rem;
  padding: 0rem;
}

.without-files {
  border: 2px dashed rgba(114, 105, 239, 0.5);
  margin: 0.25rem;
  padding: 1.4rem;
}

.validation-error-message {
  position: absolute;
  top: 48px;
  width: auto;
  padding: 3px 15px;
  border-radius: 5px;
  background-color: #FF4757;
  text-align: center;
  font-size: 12px;
  color: white;
  z-index: 1;
}

.slider-wrapper {
  max-width: 550px;
  padding: 1rem 0rem 0rem !important;
}

.slide {
  width: 100%;
  max-width: 550px;
  margin: auto;
}

.slide img {
  width: auto !important;
  max-width: 31.375rem;
  height: auto !important;
  max-height: 270px;
  min-height: 120px;
  background-color: #F7F7F7;
  margin: auto;
  object-fit: contain;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.slide-container {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 550px;
  height: 100%;
  margin: auto;
}

.thumbs {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.2rem 0rem;
  padding: 0rem !important;
}

.thumbs-wrapper {
  margin: 0px auto !important;
}

.thumb {
  width: 70px !important;
  height: 70px !important;
  margin-left: 3px !important;
  margin-right: 3px !important;
  padding: 5px !important;
  border: none !important;
}

.thumb:focus {
  border: none;
}

.thumb img {
  width: 100% !important;
  height: 100%;
  object-fit: cover;
  background-color: #E9EDEF;
  border-radius: 4px;
  border: 2px solid transparent;
}

.thumb.selected img {
  border: 2px solid white;
  outline: 3px solid mediumpurple;
}

.recorder-container {
    padding: 0.5rem 0.2rem 0.42rem;
    background-color: #F5F7FB;
    border-radius: 0.4rem;
    margin-right: 1rem;
    margin-left: 1rem;

    @media (max-width: 768px) {
        margin-right: 0.5rem;
        margin-left: 0.5rem;
    }
}

.timer-container {
    width: 2.6rem;
    text-align: center;
}

.cancel-record {
    margin: 0px 10px 3px;
    color: #FF4757;
}

.send-record {
    margin: 0px 10px 3px;
    color: #10C999;
}

.cursor-pointer {
    cursor: pointer;
}

.flash-icon-record {
    margin: 0px 6px 3px 2px;
    color: #FF4757;
    animation-name: flashing;
    animation-duration: 1.4s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    -webkit-animation-name: flashing;
    -webkit-animation-duration: 1.4s;
    -webkit-animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
}

.template-campaign-name-container {
    background: #f5f7fb;
    width: fit-content;
    padding: 0.35rem 1rem;
    border-radius: 0.5rem;
    margin-right: 0.5rem;
    font-size: 12px;
    font-weight: 600;
    box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
    text-align: left;
    float: left;
    border: 1px solid #a8a8a8;
}

@-moz-keyframes flashing{
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
}

@-webkit-keyframes flashing {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes flashing {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
}

@media (max-width: 768px) {
  .btn-lg, .btn-group-lg > .btn {
    padding: 0.5rem 0.8rem;
  }
}

.sidebar-tab {
  background-color: white;
  font-size: 14px;
  font-weight: 500;
  color: #7A7F9A;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid transparent;
  transition: all 0.2s ease;
}

.sidebar-tab:hover {
  color: #495057;
}

.sidebar-tab-selected {
  color: #7269EF !important;
  border-bottom-color: #7269EF;
}

.chat-bottom-section form {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

@media (max-width: 991px) {
  .chat-bottom-section form {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.item>span {
    transition: 0.3s all ease;
}

.item:hover {
    color: #495057 !important;
}

.schedule-msg-btn .dropup.dropdown {
    display: inline-flex;
}

.schedule-msg-btn label#scheduleMessage {
    text-decoration: none !important;
    box-shadow: none !important;
}

.popover {
    border: 1px solid #f0eff5 !important;
}

.popover .arrow::before {
    border-top-color: #f0eff5 !important;
}

.schedule-msg-popover .popover-header {
    font-size: 14px;
    font-weight: normal;
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.schedule-msg-popover .popover-header .close {
    opacity: 0.15;
}

.schedule-msg-popover .popover-body {
    font-size: 14px;
    color: #7a7f9a;
    padding: 0 1rem 0;
}

.schedule-msg-popover-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;
}

.schedule-msg-popover-container select {
    border: 0;
    padding-right: 0.5rem;
    outline: none;
}

.schedule-msg-popover-container .flatpickr-input {
    border: 0;
    text-align: center;
    width: 5.75rem;
    outline: none;
}

.schedule-msg-popover-container button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px !important;
    width: 42px !important;
    height: 38px;
}

.schedule-msg-popover .alert-message-margin {
    margin-top: -47px;
}

.schedule-msg-popover .mb-12px {
    margin-bottom: 12px;
}

.flatpickr-calendar.noCalendar {
    width: 10rem !important;
    margin-left: -25px;
}

.flatpickr-calendar.noCalendar .flatpickr-time {
    border-radius: 0.25rem;
}

.flatpickr-calendar.noCalendar.arrowTop:after,
.flatpickr-calendar.noCalendar.arrowTop:before {
    left: 80px;
    border-bottom-color: #d7d7d7;
}

.rich-text p {
    margin-bottom: 0px;
}
